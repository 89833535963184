import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { GatsbyImage } from "gatsby-plugin-image";
import defFI from '../../content/assets/images/regga-sig-pp.png'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.blog.edges,
  quotes = data.quotes.edges,
  defImg = data.defImg?.childImageSharp?.resize?.src

  const sRow = data.codings.edges.length === 2 ? ' card-2items' : ''

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="Regga @reggarantai" image={defFI}
        meta={[
          {
            name: `keywords`,
            content: ['regga', 'regga rantai', 'reggarantai','@reggarantai','gerakan jemari akhir pekan'].join(",")
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image:width`,
            content: `907`,
          },
          {
            property: `og:image:height`,
            content: `907`,
          },
          {
            property: `og:image:type`,
            content: `image/png`,
          }
        ]}
      />
      <div
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${defImg || ''})`,
          backgroundColor: '#000',
          backgroundPosition: 'bottom center'
        }}
        >
        <div className="home-overlay">
          <br /><br />
          <header id="homebg">
          {
            (
              <>
              <h1
                style={{
                  marginTop: 0,
                  color: "#fff",
                  lineHeight: 0.5,
                  letterSpacing:'1px'
                }}
                className="animate__animated animate__pulse"
              >
                Assalaamu'alaikum
              </h1>
              <p style={{color:'#fff'}}>.. mari silahkan masuk ..</p>
              </>
            )
          }
          </header>
        </div>
      </div>

      <div id="home-body">
        <section id="articles" className="section">
          <div id="latest-article">
            <p style={{height:0,overflow:'hidden',margin:0,opacity:0}}>Assalaamu'alaikum.. mari silahkan masuk.. </p>
            <div className="container cards">
              <div className="row">
                {
                  posts.map(({ node}) => {
                    const title = node.frontmatter.title || node.fields.slug
                      return (
                        <div key={node.fields.slug} className="card">
                          <div className="card-inner">
                            <Link to={node.fields.slug}>
                              <GatsbyImage
                                alt={title}
                                image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                                objectFit="cover"
                                objectPosition="50% 50%" />
                              <div className="card-body">
                                <small className="card-date">{`${node.frontmatter.hijriah}`}</small>
                                <h3>{title}</h3>
                                <p className="card-p"
                                  dangerouslySetInnerHTML={{
                                    __html: node.frontmatter.description || node.excerpt,
                                  }}
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                }
              </div>
            </div>
          </div>

        </section>
      </div>

      <section id="quotes" className="section" style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${defImg || ''})`,
        backgroundColor: '--bgDark',
        backgroundPosition: 'top center'
      }}>
        <div className="overlay"></div>
        <div className="container section-title section-title-white"><div className="row"><div className="col-sm-12">
            <h2>Kutipan</h2>
        </div></div></div>
        <div className="container cards cards-transparent">
          <div className="row" data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease">
            {
              quotes.map(({ node}) => {
                return (
                  <div key={node.fields.slug} className="card3">
                    <div className="card-inner">
                      <Link to={node.fields.slug}>
                        <GatsbyImage
                          alt={node.frontmatter.title}
                          image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                          objectFit="cover"
                          objectPosition="50% 50%" />
                      </Link>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>

      { data.codings.edges.length ? (
        <section id="codings" className="section" style={{
          backgroundColor: '--bgDark'
        }}>
          <div className="container section-title section-title-white"><div className="row"><div className="col-sm-12">
            <h2>Coding</h2>
          </div></div></div>
          <div className={`container cards cards-transparent${sRow}`}>
            <div className="row" data-sal="fade"
            data-sal-duration="2000"
            data-sal-easing="ease">
              {
                data.codings.edges.map(({ node}) => {
                  return (
                    <div key={node.fields.slug} className="card">
                      <div className="card-inner">
                        <Link to={node.fields.slug}>
                          <GatsbyImage
                            alt={node.frontmatter.title}
                            image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                            objectFit="cover"
                            objectPosition="50% 50%" />
                          <div className="card-body">
                            <small className="card-date">{
                              node.frontmatter.tags.join(", ")
                            }</small>
                            <h3>{node.frontmatter.title}</h3>
                            <p className="card-p"
                              dangerouslySetInnerHTML={{
                                __html: node.frontmatter.description || node.excerpt,
                              }}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </section>) : null
      }

    </Layout>
  );
}

export default BlogIndex

export const pageQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  blog: allMdx(
    filter: {frontmatter: {postType: {eq: "blog"}, draft: {eq: false}}}
    limit: 12
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          hijriah
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 460, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  quotes: allMdx(
    filter: {frontmatter: {postType: {eq: "kutipan"}}}
    limit: 3
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 640, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  codings: allMdx(
    filter: {frontmatter: {postType: {eq: "coding"}, draft: {eq: false}}}
    limit: 4
    sort: {fields: [frontmatter___date], order: DESC}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          tags
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 460, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  defImg: file(relativePath: {eq: "images/regga-coffee.jpg"}) {
    childImageSharp {
      resize(width: 10) {
        src
      }
    }
  }
}
`
